import React from 'react'
import { IIcon } from '../../ts'

export default function SamsaIcon(props: IIcon): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 628.84 197.94">
      <path
        className="a"
        d="M49.6,23.67h73.24v22H49.6q-7.32,0-7.32,7.32V60.3q0,7.32,7.32,7.32H64.25v22H49.6Q13,89.59,13,60.3V53Q13,23.67,49.6,23.67ZM86.22,126.21H13v-22H86.22q7.32,0,7.33-7.32t-7.33-7.33H71.57v-22H86.22q36.63,0,36.62,29.3T86.22,126.21Z"
      />
      <path className="a" d="M178.8,79.92l-23.51,46.29H123.8L163.49,49.6ZM168,41l15.16-29.22,59.33,114.47H211Z" />
      <path
        className="a"
        d="M278.34,67.47v58.74H249V23.67h38.09l43,85.26L315,138.15Zm102.54-43.8V126.21h-29.3V67.47l-17,32.82L319.28,70l23.51-46.29Z"
      />
      <path
        className="a"
        d="M432.15,23.67h73.24v22H432.15q-7.33,0-7.33,7.32V60.3q0,7.32,7.33,7.32h14.64v22H432.15q-36.63,0-36.63-29.29V53Q395.52,23.67,432.15,23.67Zm36.62,102.54H395.52v-22h73.25q7.32,0,7.32-7.32t-7.32-7.33H454.12v-22h14.65q36.62,0,36.62,29.3T468.77,126.21Z"
      />
      <path className="a" d="M561.35,79.92l-23.52,46.29H506.34L546,49.6ZM550.51,41l15.16-29.22L625,126.21H593.5Z" />
      <path
        className="b"
        d="M37.37,13.57h73.24v22H37.37q-7.32,0-7.32,7.32v7.33q0,7.32,7.32,7.32H52v22H37.37Q.76,79.49.75,50.19V42.86Q.75,13.56,37.37,13.57ZM74,116.11H.75v-22H74q7.32,0,7.33-7.32T74,79.49H59.34v-22H74q36.63,0,36.62,29.3T74,116.11Z"
      />
      <path className="b" d="M166.57,69.82l-23.51,46.29H111.57L151.26,39.5Zm-10.84-39L170.89,1.63l59.33,114.48h-31.5Z" />
      <path
        className="b"
        d="M266.11,57.37v58.74h-29.3V13.57H274.9l43,85.25-15.16,29.23Zm102.54-43.8V116.11h-29.3V57.37l-17,32.81L307.05,59.86l23.51-46.29Z"
      />
      <path
        className="b"
        d="M419.92,13.57h73.24v22H419.92q-7.34,0-7.33,7.32v7.33q0,7.32,7.33,7.32h14.64v22H419.92q-36.63,0-36.63-29.3V42.86Q383.29,13.56,419.92,13.57Zm36.62,102.54H383.29v-22h73.25q7.32,0,7.32-7.32t-7.32-7.32H441.89v-22h14.65q36.61,0,36.62,29.3T456.54,116.11Z"
      />
      <path className="b" d="M549.11,69.82,525.6,116.11H494.11l39.7-76.61Zm-10.84-39L553.44,1.63l59.32,114.48H581.27Z" />
      <path
        className="c"
        d="M37.71,166.12a15.65,15.65,0,0,1-15.93,15.61H15V196H4.56V150.52H21.78A15.64,15.64,0,0,1,37.71,166.12Zm-10.4,0a5.56,5.56,0,0,0-5.53-5.85H15V172h6.82A5.57,5.57,0,0,0,27.31,166.12Z"
      />
      <path
        className="c"
        d="M82.69,181.08H77.75V196H67.35V150.52h18.2a15.57,15.57,0,0,1,15.6,15.6,15,15,0,0,1-8.39,13.2L102.45,196H91.27ZM77.75,172h7.8c2.86,0,5.2-2.54,5.2-5.86s-2.34-5.85-5.2-5.85h-7.8Z"
      />
      <path
        className="c"
        d="M129.16,173.27a23.66,23.66,0,1,1,23.66,23.67A23.46,23.46,0,0,1,129.16,173.27Zm36.92,0c0-8.12-5.85-13.52-13.26-13.52s-13.26,5.4-13.26,13.52,5.85,13.53,13.26,13.53S166.08,181.4,166.08,173.27Z"
      />
      <path
        className="c"
        d="M247.07,173.27c0,12.87-9.55,22.76-22.1,22.76h-18.2V150.52H225C237.52,150.52,247.07,160.4,247.07,173.27Zm-10,0c0-7.79-4.94-12.74-12.09-12.74h-7.8V186H225C232.12,186,237.06,181.08,237.06,173.27Z"
      />
      <path
        className="c"
        d="M276.71,181.34V150.52h10.4v29.91c0,3.44,1.63,6.37,7.15,6.37s7.15-2.93,7.15-6.37V150.52h10.4v30.82c0,9.75-7.54,15.6-17.55,15.6S276.71,191.09,276.71,181.34Z"
      />
      <path
        className="c"
        d="M341.78,173.27c0-13.39,9.94-23.66,23.66-23.66a22.72,22.72,0,0,1,19.5,10.53l-9,5.21c-2-3.52-5.92-5.6-10.53-5.6-8.06,0-13.26,5.4-13.26,13.52s5.2,13.53,13.26,13.53c4.61,0,8.58-2.08,10.53-5.59l9,5.2a22.54,22.54,0,0,1-19.5,10.53C351.72,196.94,341.78,186.67,341.78,173.27Z"
      />
      <path className="c" d="M444.8,160.53H433.1V196H422.7v-35.5H411v-10h33.8Z" />
      <path className="c" d="M484.19,150.52V196h-10.4V150.52Z" />
      <path
        className="c"
        d="M514.48,173.27a23.66,23.66,0,1,1,23.66,23.67A23.46,23.46,0,0,1,514.48,173.27Zm36.92,0c0-8.12-5.85-13.52-13.26-13.52s-13.26,5.4-13.26,13.52,5.85,13.53,13.26,13.53S551.4,181.4,551.4,173.27Z"
      />
      <path className="c" d="M627.84,150.52V196H620l-17.55-24.71V196h-10.4V150.52h7.8l17.55,24.71V150.52Z" />
      <path
        className="b"
        d="M34,163.12a15.65,15.65,0,0,1-15.93,15.61H11.27V193H.87V147.52H18.09A15.64,15.64,0,0,1,34,163.12Zm-10.4,0a5.56,5.56,0,0,0-5.53-5.85H11.27V169h6.82A5.57,5.57,0,0,0,23.62,163.12Z"
      />
      <path
        className="b"
        d="M79,178.08H74.06V193H63.66V147.52h18.2a15.57,15.57,0,0,1,15.6,15.6,15,15,0,0,1-8.39,13.2L98.76,193H87.58ZM74.06,169h7.8c2.86,0,5.2-2.54,5.2-5.86s-2.34-5.85-5.2-5.85h-7.8Z"
      />
      <path
        className="b"
        d="M125.47,170.27a23.66,23.66,0,1,1,23.66,23.67A23.46,23.46,0,0,1,125.47,170.27Zm36.92,0c0-8.12-5.85-13.52-13.26-13.52s-13.26,5.4-13.26,13.52,5.85,13.53,13.26,13.53S162.39,178.4,162.39,170.27Z"
      />
      <path
        className="b"
        d="M243.38,170.27c0,12.87-9.55,22.76-22.1,22.76h-18.2V147.52h18.2C233.83,147.52,243.38,157.4,243.38,170.27Zm-10,0c0-7.79-4.94-12.74-12.09-12.74h-7.8V183h7.8C228.43,183,233.37,178.08,233.37,170.27Z"
      />
      <path
        className="b"
        d="M273,178.34V147.52h10.4v29.91c0,3.44,1.63,6.37,7.15,6.37s7.15-2.93,7.15-6.37V147.52h10.4v30.82c0,9.75-7.54,15.6-17.55,15.6S273,188.09,273,178.34Z"
      />
      <path
        className="b"
        d="M338.09,170.27c0-13.39,9.94-23.66,23.66-23.66a22.72,22.72,0,0,1,19.5,10.53l-9,5.21c-2-3.52-5.92-5.6-10.53-5.6-8.06,0-13.26,5.4-13.26,13.52s5.2,13.53,13.26,13.53c4.61,0,8.58-2.08,10.53-5.59l9,5.2a22.54,22.54,0,0,1-19.5,10.53C348,193.94,338.09,183.67,338.09,170.27Z"
      />
      <path className="b" d="M441.11,157.53h-11.7V193H419v-35.5h-11.7v-10h33.8Z" />
      <path className="b" d="M480.5,147.52V193H470.1V147.52Z" />
      <path
        className="b"
        d="M510.79,170.27a23.66,23.66,0,1,1,23.66,23.67A23.46,23.46,0,0,1,510.79,170.27Zm36.92,0c0-8.12-5.85-13.52-13.26-13.52s-13.26,5.4-13.26,13.52S527,183.8,534.45,183.8,547.71,178.4,547.71,170.27Z"
      />
      <path className="b" d="M624.15,147.52V193h-7.8L598.8,168.32V193H588.4V147.52h7.8l17.55,24.71V147.52Z" />
    </svg>
  )
}
