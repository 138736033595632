import website from '../assets/website.png'
import instagram from '../assets/instagram.png'
import telegram from '../assets/telegram.png'
import wab from '../assets/wab.png'
import whatsapp from '../assets/whatsapp.png'
import gmaps from '../assets/gmaps.png'
import drive from '../assets/drive.png'
import { ItemType } from '../ts'

const items: ItemType[] = [
  {
    title: 'Website',
    subtitle: 'Link menuju website ini',
    image: website,
    link: window.location.origin, //your personal website or portfolio link
  },
  {
    title: 'Google Maps',
    subtitle: 'Lokasi kami',
    image: gmaps,
    link: 'https://g.page/samsa-audio-rent', //GMaps Profile
  },
  {
    title: 'Instagram',
    subtitle: '@samsa.production | Follow us on Instagram',
    image: instagram,
    link: 'https://instagram.com/samsa.production', //instagram profile link
  },
  {
    title: 'WhatsApp',
    subtitle: 'Chat saya, Jangan ragu! Head Of SAMSA Production',
    image: whatsapp,
    link: 'https://wa.me/+6281335593653?text=Hallo, Saya (nama) ingin bertanya, terima kasih.', //Whatsapp Profile
  },
  {
    title: 'WhatsApp',
    subtitle: 'Chat saya, Jangan ragu! Second Head Of SAMSA Production',
    image: whatsapp,
    link: 'https://wa.me/+6282330327265?text=Hallo, Saya (nama) ingin bertanya, terima kasih.', //Whatsapp Profile
  },
  {
    title: 'WhatsApp Business',
    subtitle: 'FARID | Hanya menerima chat! Admin SAMSA Production',
    image: wab,
    link: 'https://wa.me/message/YXRLX7DBMEFRN1', //Whatsapp Business Profile
  },
  {
    title: 'Telegram',
    subtitle: '@samsaprobot | Chat saya, Jangan ragu! Admin SAMSA Production',
    image: telegram,
    link: 'https://t.me/samsaprobot', //Telegram Profile
  },
  {
    title: 'Gallery',
    subtitle: 'Drive Gallery of SAMSA Production',
    image: drive,
    link: 'https://drive.samsa.my.id', //Drive Link
  },
]

export default items
